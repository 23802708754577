<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-row class="pui-form-layout mt-4">
					<pui-field-set :title="$t('form.technicalnauticalservice.servicedata')">
						<v-layout wrap>
							<v-flex xs12 md2>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:id="`portname-${modelName}`"
											:attach="`portname-${modelName}`"
											:label="this.$t('form.technicalnauticalservice.portname')"
											toplabel
											clearable
											required
											:disabled="!isCreatingElement"
											v-model="model"
											modelName="port"
											:itemsToSelect="itemsToSelectPort"
											:modelFormMapping="{ id: 'portid' }"
											:key="'portid_' + portidKey"
											itemValue="id"
											itemText="portname"
											reactive
											:fixedFilter="filterByUserPortsAndAuthority"
										></pui-select>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12 md2>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:id="`statusname-${modelName}`"
											:attach="`statusname-${modelName}`"
											:label="this.$t('form.technicalnauticalservice.statusname')"
											toplabel
											clearable
											:disabled="true"
											v-model="model"
											modelName="technicalnauticalservicestatuspa"
											:itemsToSelect="[{ statusid: this.model.statusid }]"
											:modelFormMapping="{ statusid: 'statusid' }"
											itemValue="statusid"
											itemText="statusname"
											reactive
											:fixedFilter="filterByAuthority"
										></pui-select>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12 md2>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:id="`type-${modelName}`"
											:attach="`type-${modelName}`"
											:label="this.$t('form.technicalnauticalservice.type')"
											toplabel
											clearable
											:disabled="!isCreatingElement"
											v-model="model"
											modelName="technicalnauticalservicetype"
											:itemsToSelect="[{ technicalnauticalservicetypeid: this.model.servicetypeid }]"
											:modelFormMapping="{ technicalnauticalservicetypeid: 'servicetypeid' }"
											itemValue="technicalnauticalservicetypeid"
											itemText="description"
											reactive
											:fixedFilter="filterByTypes"
											required
										></pui-select>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:id="`visitid-${modelName}`"
											:attach="`visitid-${modelName}`"
											:label="$t('form.technicalnauticalservice.portcallnumber')"
											toplabel
											clearable
											required
											:disabled="!model.portid || !isCreatingElement"
											v-model="model"
											modelName="visit"
											:itemsToSelect="itemsToSelectVisits"
											:modelFormMapping="{ id: 'visitid' }"
											:itemValue="['id']"
											:itemText="
												(item) =>
													`${item.portcallnumber} - ${item.vesselname} - ${this.$dateTimeUtils.getLocalFormattedDate(
														item.eta,
														'DD/MM/YYYY HH:mm'
													)} - ${this.$dateTimeUtils.getLocalFormattedDate(item.etd, 'DD/MM/YYYY HH:mm')} - ${item.status}`
											"
											:order="{ eta: 'asc' }"
											:fixedFilter="portidFixedFilter"
											:key="'portid_' + portidKey"
											reactive
											detailComponentName="visit-form"
											detailModelName="visit"
										></pui-select>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:id="`stopid-${modelName}`"
											:attach="`stopid-${modelName}`"
											:label="$t('form.technicalnauticalservice.stopnum')"
											toplabel
											clearable
											:disabled="!model.visitid || !isCreatingElement"
											v-model="model"
											modelName="stop"
											:itemsToSelect="itemsToSelectStops"
											:modelFormMapping="{ id: 'stopid' }"
											itemValue="id"
											:itemText="(item) => getStopItemText(item)"
											:order="{ num: 'asc' }"
											:fixedFilter="visitidFixedFilter"
											:key="'stopid_' + visitidKey"
											reactive
											required
											detailComponentName="stop-form"
											detailModelName="stop"
										></pui-select>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12 md6>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:id="`movementid-${modelName}`"
											:attach="`movementid-${modelName}`"
											:label="$t('form.technicalnauticalservice.movementnum')"
											toplabel
											clearable
											:disabled="!model.stopid || !isCreatingElement"
											v-model="model"
											modelName="movement"
											:itemsToSelect="itemsToSelectMovementNum"
											:modelFormMapping="{ id: 'movementid' }"
											itemValue="id"
											:itemText="
												(item) =>
													`${item.nummovement} - ${item.movementtype} - ${this.$dateTimeUtils.getLocalFormattedDate(
														item.starttime,
														'DD/MM/YYYY HH:mm'
													)} - ${this.$dateTimeUtils.getLocalFormattedDate(item.endtime, 'DD/MM/YYYY HH:mm')}
											- ${item.movementstatus}`
											"
											:order="{ nummovement: 'asc' }"
											:fixedFilter="movementVisitidFixedFilter"
											:key="'movementid_' + visitidKey"
											required
											reactive
											detailComponentName="movement-form"
											detailModelName="movement"
										></pui-select>
									</v-flex>
								</v-layout>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-row>
			</v-row>
			<v-row class="pui-form-layout">
				<pui-field-set :title="$t('form.technicalnauticalservice.reqdate')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`startdatereq-${modelName}`"
										:label="$t('form.technicalnauticalservice.startdatereq')"
										v-model="model.startdatereq"
										toplabel
										clearable
										:disabled="formDisabled || model.statusid !== 1"
										time
										:max="model.enddatereq"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`enddatereq-${modelName}`"
										:label="$t('form.technicalnauticalservice.enddatereq')"
										v-model="model.enddatereq"
										toplabel
										clearable
										:disabled="formDisabled || model.statusid !== 1"
										time
										:min="model.startdatereq"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-row>
			<v-row class="pui-form-layout">
				<pui-field-set :title="$t('form.technicalnauticalservice.planneddate')" v-if="hasServiceStatusId(4)">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`startdateplanned-pilotage`"
										v-model="model.startdateplanned"
										:label="$t('form.technicalnauticalservice.startdateplanned')"
										:disabled="formDisabled || model.statusid !== 4"
										toplabel
										time
										:max="model.enddateplanned"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`enddateplanned-pilotage`"
										v-model="model.enddateplanned"
										:label="$t('form.technicalnauticalservice.enddateplanned')"
										:disabled="formDisabled || model.statusid !== 4"
										toplabel
										time
										:min="model.startdateplanned"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
				<pui-field-set :title="$t('form.pilotage.estimateddate')" v-if="hasServiceStatusId(5)">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`startdateestimated-pilotage`"
										v-model="model.startdateestimated"
										:label="$t('form.technicalnauticalservice.startdateestimated')"
										:disabled="formDisabled || model.statusid !== 5"
										toplabel
										time
										:max="model.enddateestimated"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`enddateestimated-pilotage`"
										v-model="model.enddateestimated"
										:label="$t('form.technicalnauticalservice.enddateestimated')"
										:disabled="formDisabled || model.statusid !== 5"
										toplabel
										time
										:min="model.startdateestimated"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-row>
			<v-row class="pui-form-layout">
				<pui-field-set :title="$t('form.technicalnauticalservice.actualdate')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`startdateactual-${modelName}`"
										:label="$t('form.technicalnauticalservice.startdateactual')"
										v-model="model.startdateactual"
										toplabel
										:disabled="formDisabled || model.statusid !== 2"
										time
										:max="model.enddateactual"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`enddateactual-${modelName}`"
										:label="$t('form.technicalnauticalservice.enddateactual')"
										v-model="model.enddateactual"
										toplabel
										:disabled="formDisabled || model.statusid !== 3"
										time
										:min="model.startdateactual"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-row>
			<v-row class="pui-form-layout">
				<v-layout wrap>
					<v-flex xs12 md6 v-if="!isCreatingElement && model.statusid === 6">
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									:id="`cancelationreason-${modelName}`"
									:attach="`cancelationreason-${modelName}`"
									v-model="model"
									:label="$t('form.technicalnauticalservice.cancelationreason')"
									toplabel
									clearable
									disabled
									modelName="cancelationreason"
									:itemsToSelect="[{ id: model.cancelationreasonid }]"
									:modelFormMapping="{ id: 'cancelationreasonid' }"
									itemValue="id"
									itemText="description"
									reactive
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md12>
						<v-layout wrap>
							<v-flex xs12>
								<pui-text-area
									:id="`comments-technicalnauticalservice`"
									v-model="model.comments"
									:label="$t('form.technicalnauticalservice.comments')"
									:disabled="formDisabled || model.statusid === 6"
									toplabel
									maxlength="300"
								></pui-text-area>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'technicalnauticalservice-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'technicalnauticalservice',
			portidKey: 0,
			portidFixedFilter: null,
			visitidKey: 0,
			visitidFixedFilter: null,
			movementVisitidFixedFilter: null,
			visiteta: null,
			visitetd: null,
			statusAp: []
		};
	},
	methods: {
		afterGetData() {
			if (this.isCreatingElement) {
				this.model.portauthorityid = window.localStorage.getItem('workingPortAuthorityId');
			}
		},
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			let enddate = item.atd ? item.atd : item.etdauth ? item.etdauth : item.etd;

			return `${item.num} - ${this.$dateTimeUtils.getLocalFormattedDate(
				startdate,
				'DD/MM/YYYY HH:mm'
			)} - ${this.$dateTimeUtils.getLocalFormattedDate(enddate, 'DD/MM/YYYY HH:mm')} - ${item.status} - ${item.berthnowname}`;
		},
		hasServiceStatusId(statusid) {
			return this.statusAp.includes(statusid);
		},
		updateValidations(visitid) {
			const opts = {
				model: 'visit',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.model.visitid ? this.model.visitid : visitid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0]) {
					this.visiteta = response.data.data[0].eta;
					this.visitetd = response.data.data[0].etd;
				}
			});
		}
	},
	computed: {
		itemsToSelectPort() {
			return [{ id: this.model.portid }];
		},
		itemsToSelectVisits() {
			return [{ id: this.model.visitid }];
		},
		itemsToSelectStops() {
			return [{ id: this.model.stopid }];
		},
		itemsToSelectMovementNum() {
			return [{ id: this.model.movementid }];
		},
		visitStatusCodes() {
			return this.$store.getters.getActiveVisitStatusid;
		},
		stopStatusCodes() {
			return this.$store.getters.getActiveStopStatusid;
		},
		movementStatusCodes() {
			return this.$store.getters.getActiveMovementStatusid;
		},
		filterByUserPortsAndAuthority() {
			const userPorts = this.$store.getters.getPortsIds;
			const userPortAuthority = window.localStorage.getItem('workingPortAuthorityId');
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'id', op: 'in', data: userPorts },
					{ field: 'portauthorityid', op: 'eq', data: userPortAuthority }
				]
			};
		},
		filterByAuthority() {
			const userPortAuthority = window.localStorage.getItem('workingPortAuthorityId');
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'portauthorityid', op: 'eq', data: userPortAuthority }]
			};
		},
		filterByTypes() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'technicalnauticalservicetypeid', op: 'ni', data: [1, 2, 3] }]
			};
		}
	},
	created() {},
	mounted() {
		const opts2 = {
			model: 'technicalnauticalservicestatuspa',
			filter: {
				groupOp: 'and',
				groups: [],
				rules: [
					{
						field: 'port_authority_id',
						op: 'eq',
						data: window.localStorage.getItem('workingPortAuthorityId')
					}
				]
			}
		};
		this.$puiRequests.postRequest('/puisearch', opts2, (response) => {
			if (response.data) {
				//Get towage data visit
				this.statusAp = response.data.data.map((sap) => {
					return sap.statusid;
				});
			}
		});
	},
	watch: {
		'model.portid'(newVal) {
			if (newVal) {
				this.portidFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'portid', op: 'eq', data: newVal },
						{ field: 'statusid', op: 'in', data: this.visitStatusCodes }
					]
				};
			} else {
				this.portidFixedFilter = null;
				this.visitidFixedFilter = null;
				this.movementVisitidFixedFilter = null;
				this.model.portid = null;
				this.model.stopid = null;
				this.model.movementid = null;
			}
			this.portidKey += 1;
		},
		'model.visitid'(newVal) {
			if (newVal) {
				this.visitidFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'visitid', op: 'eq', data: newVal },
						{ field: 'statusid', op: 'in', data: this.stopStatusCodes }
					]
				};
				this.movementVisitidFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'visitid', op: 'eq', data: newVal },
						{ field: 'status', op: 'in', data: this.movementStatusCodes }
					]
				};
			} else {
				this.visitidFixedFilter = null;
				this.movementVisitidFixedFilter = null;
				this.model.stopid = null;
			}
			this.visitidKey += 1;
			this.updateValidations();
		}
	}
};
</script>
